/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
}
.lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple.purple div {
    border: 4px solid #4d4dff;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes lds-ripple {
    0% {
        top: 18px;
        left: 18px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 36px;
        height: 36px;
        opacity: 0;
    }
}
