/*
We declare all variables here as default so that they are easy to override...
*/
/* Border, shadows, ... */
/* Colors */
/* Spacing */
/* Breakpoints */
.klaro .cookie-modal,
.klaro .cookie-notice {
  /* The switch - the box around the slider */
  font-size: 14px;
}

.klaro .cookie-modal .cm-switch-container,
.klaro .cookie-notice .cm-switch-container {
  display: block;
  position: relative;
  padding: 10px;
  padding-left: 66px;
  line-height: 20px;
  vertical-align: middle;
  min-height: 40px;
  border-bottom: 1px solid #ccc;
}

.klaro .cookie-modal .cm-switch-container:last-child,
.klaro .cookie-notice .cm-switch-container:last-child {
  border-bottom: 0;
}

.klaro .cookie-modal .cm-switch-container:first-child,
.klaro .cookie-notice .cm-switch-container:first-child {
  margin-top: 0;
}

.klaro .cookie-modal .cm-switch-container p,
.klaro .cookie-notice .cm-switch-container p {
  margin-top: 0;
}

.klaro .cookie-modal .cm-switch,
.klaro .cookie-notice .cm-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

.klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider {
  background-color: #9082ff;
  background-color: var(--green1, #9082ff);
}

.klaro .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.half-checked:checked + .cm-list-label .slider {
  background-color: #9082ff;
  background-color: var(--green1, #9082ff);
  opacity: 0.6;
}

.klaro .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input.half-checked:checked + .cm-list-label .slider::before {
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

.klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider {
  background-color: #24cc9a;
  background-color: var(--green2, #24cc9a);
  opacity: 0.8;
}

.klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider::before {
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

.klaro .cookie-modal .cm-list-input.required:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.required:checked + .cm-list-label .slider {
  background-color: #24cc9a;
  background-color: var(--green2, #24cc9a);
  opacity: 0.8;
  cursor: not-allowed;
}

.klaro .cookie-modal .slider,
.klaro .cookie-notice .slider {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
}

.klaro .cookie-modal .cm-list-input,
.klaro .cookie-notice .cm-list-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 50px;
  height: 30px;
}

.klaro .cookie-modal .cm-list-title,
.klaro .cookie-notice .cm-list-title {
  font-size: 14px;
  font-weight: 600;
}

.klaro .cookie-modal .cm-list-description,
.klaro .cookie-notice .cm-list-description {
  font-size: 14px;
  padding-top: 4px;
  color: #666;
}

.klaro .cookie-modal .cm-list-label,
.klaro .cookie-notice .cm-list-label {
  /* The slider */
  /* Rounded sliders */
}

.klaro .cookie-modal .cm-list-label .cm-switch,
.klaro .cookie-notice .cm-list-label .cm-switch {
  position: absolute;
  left: 0;
}

.klaro .cookie-modal .cm-list-label .slider,
.klaro .cookie-notice .cm-list-label .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  width: 50px;
  display: inline-block;
}

.klaro .cookie-modal .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-label .slider::before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 5px;
  background-color: #fff;
  transition: 0.4s;
}

.klaro .cookie-modal .cm-list-label .slider.round,
.klaro .cookie-notice .cm-list-label .slider.round {
  border-radius: 30px;
}

.klaro .cookie-modal .cm-list-label .slider.round::before,
.klaro .cookie-notice .cm-list-label .slider.round::before {
  border-radius: 50%;
}

.klaro .cookie-modal .cm-list-label input:focus + .slider,
.klaro .cookie-notice .cm-list-label input:focus + .slider {
  box-shadow-color: #48dfb2;
  box-shadow-color: var(--green3, #48dfb2);
  box-shadow: 0 0 1px #48dfb2;
}

.klaro .cookie-modal .cm-list-label input:checked + .slider::before,
.klaro .cookie-notice .cm-list-label input:checked + .slider::before {
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.klaro .cookie-modal .cm-list-input:focus + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input:focus + .cm-list-label .slider {
  box-shadow: 0 4px 6px 0 rgba(125, 125, 125, 0.2), 5px 5px 10px 0 rgba(125, 125, 125, 0.19);
}

.klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider::before {
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.klaro .cookie-modal .slider,
.klaro .cookie-notice .slider {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
}

.klaro .cookie-modal a,
.klaro .cookie-notice a {
  color: #9082ff;
  color: var(--green1, #9082ff);
  text-decoration: none;
}

.klaro .cookie-modal p,
.klaro .cookie-modal strong,
.klaro .cookie-modal h1,
.klaro .cookie-modal h2,
.klaro .cookie-modal ul,
.klaro .cookie-modal li,
.klaro .cookie-notice p,
.klaro .cookie-notice strong,
.klaro .cookie-notice h1,
.klaro .cookie-notice h2,
.klaro .cookie-notice ul,
.klaro .cookie-notice li {
  color: #eee;
  color: var(--font-color-dark, #eee);
}

.klaro .cookie-modal p,
.klaro .cookie-modal h1,
.klaro .cookie-modal h2,
.klaro .cookie-modal ul,
.klaro .cookie-modal li,
.klaro .cookie-notice p,
.klaro .cookie-notice h1,
.klaro .cookie-notice h2,
.klaro .cookie-notice ul,
.klaro .cookie-notice li {
  display: block;
  text-align: left;
  margin: 0;
  padding: 0;
  margin-top: 0.7em;
}

.klaro .cookie-modal .cm-link,
.klaro .cookie-notice .cm-link {
  margin-right: 0.5em;
  vertical-align: middle;
}

.klaro .cookie-modal .cm-btn,
.klaro .cookie-notice .cm-btn {
  color: #eee;
  color: var(--font-color-dark, #eee);
  background: #555;
  border-radius: 6px;
  padding: 6px 10px;
  margin-right: 0.5em;
  border: 0;
  padding: 0.4em;
  font-size: 1em;
}

.klaro .cookie-modal .cm-btn:disabled,
.klaro .cookie-notice .cm-btn:disabled {
  opacity: 0.5;
}

.klaro .cookie-modal .cm-btn.cm-btn-close,
.klaro .cookie-notice .cm-btn.cm-btn-close {
  background: #aaa;
  color: #000;
}

.klaro .cookie-modal .cm-btn.cm-btn-success,
.klaro .cookie-notice .cm-btn.cm-btn-success {
  background: #9082ff;
  background: var(--green1, #9082ff);
}

.klaro .cookie-modal .cm-btn.cm-btn-info,
.klaro .cookie-notice .cm-btn.cm-btn-info {
  background: #2581c4;
  background: var(--blue1, #2581c4);
}

.klaro .cookie-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  left: 0;
  top: 0;
  z-index: 1000;
}

.klaro .cookie-modal.cm-embedded {
  position: relative;
  height: inherit;
  width: inherit;
  left: inherit;
  right: inherit;
  z-index: 0;
}

.klaro .cookie-modal.cm-embedded .cm-modal.cm-klaro {
  position: relative;
  -ms-transform: none;
      transform: none;
}

.klaro .cookie-modal .cm-bg {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.klaro .cookie-modal .cm-modal.cm-klaro {
  z-index: 1001;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  max-height: 98%;
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  position: fixed;
  overflow: auto;
  background: #333;
  color: #eee;
}

@media (min-width: 660px) {
  .klaro .cookie-modal .cm-modal.cm-klaro {
    border-radius: 4px;
    position: relative;
    margin: 0 auto;
    max-width: 640px;
    height: auto;
    width: auto;
  }
}

.klaro .cookie-modal .cm-modal .hide {
  border-style: none;
  background: none;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.klaro .cookie-modal .cm-modal .hide svg {
  stroke: #eee;
}

.klaro .cookie-modal .cm-modal .cm-footer {
  padding: 1em;
  border-top: 1px solid #555;
}

.klaro .cookie-modal .cm-modal .cm-footer-buttons {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.klaro .cookie-modal .cm-modal .cm-footer .cm-powered-by {
  font-size: 0.8em;
  padding-top: 4px;
  text-align: right;
  padding-right: 8px;
}

.klaro .cookie-modal .cm-modal .cm-footer .cm-powered-by a {
  color: #999;
}

.klaro .cookie-modal .cm-modal .cm-header {
  padding: 1em;
  padding-right: 24px;
  border-bottom: 1px solid #555;
}

.klaro .cookie-modal .cm-modal .cm-header h1 {
  margin: 0;
  font-size: 2em;
  display: block;
}

.klaro .cookie-modal .cm-modal .cm-header h1.title {
  padding-right: 20px;
}

.klaro .cookie-modal .cm-modal .cm-body {
  padding: 1em;
}

.klaro .cookie-modal .cm-modal .cm-body ul {
  display: block;
}

.klaro .cookie-modal .cm-modal .cm-body span {
  display: inline-block;
  width: auto;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes {
  padding: 0;
  margin: 0;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-caret,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-caret {
  color: #888;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-content,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-content {
  margin-left: -40px;
  display: none;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-content.expanded,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-content.expanded {
  margin-top: 10px;
  display: block;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose {
  position: relative;
  line-height: 20px;
  vertical-align: middle;
  padding-left: 60px;
  min-height: 40px;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service:first-child,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose:first-child,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service:first-child,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose:first-child {
  margin-top: 0;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service p,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose p,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service p,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose p {
  margin-top: 0;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service p.purposes,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose p.purposes,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service p.purposes,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose p.purposes {
  font-size: 0.8em;
  color: #999;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service.cm-toggle-all,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose.cm-toggle-all,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service.cm-toggle-all,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose.cm-toggle-all {
  border-top: 1px solid #555;
  padding-top: 1em;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-list-title,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-list-title,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-list-title,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-list-title {
  font-weight: 600;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-required,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-required,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-required,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-required {
  padding-left: 0.2em;
  font-size: 0.8em;
  color: #999;
}

.klaro .cookie-notice:not(.cookie-modal-notice) {
  background: #333;
  z-index: 999;
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
}

@media (min-width: 1024px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) {
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
    border-radius: 4px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    max-width: 400px;
  }
}

@media (max-width: 1023px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) {
    border-style: none;
    border-radius: 0;
  }
}

.klaro .cookie-notice:not(.cookie-modal-notice).cn-embedded {
  position: relative;
  height: inherit;
  width: inherit;
  left: inherit;
  right: inherit;
  bottom: inherit;
  z-index: 0;
}

.klaro .cookie-notice:not(.cookie-modal-notice).cn-embedded .cn-body {
  padding-top: 0.5em;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body {
  margin-bottom: 0;
  margin-right: 0;
  bottom: 0;
  padding: 1em;
  padding-top: 0;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body p {
  margin-bottom: 0.5em;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body p.cn-changes {
  text-decoration: underline;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-learn-more {
  display: inline-block;
  flex-grow: 1;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons {
  display: inline-block;
  margin-top: -0.5em;
}

@media (max-width: 384px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons {
    width: 100%;
  }
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn {
  margin-top: 0.5em;
}

@media (max-width: 384px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn {
    width: calc(50% - 0.5em);
  }
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok {
  margin-top: -0.5em;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: right;
  align-items: baseline;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok a,
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok div {
  margin-top: 0.5em;
}

.klaro .cookie-modal-notice {
  z-index: 1001;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  max-height: 98%;
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  position: fixed;
  overflow: auto;
  background: #333;
  color: #eee;
  padding: 1em;
  padding-top: 0.2em;
}

@media (min-width: 400px) {
  .klaro .cookie-modal-notice {
    border-radius: 4px;
    position: relative;
    margin: 0 auto;
    max-width: 400px;
    height: auto;
    width: auto;
  }
}

.klaro .cookie-modal-notice .cn-ok {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
}

.klaro .cookie-notice-hidden {
  display: none !important;
}

/*
We declare all variables here as default so that they are easy to override...
*/
/* Border, shadows, ... */
/* Colors */
/* Spacing */
/* Breakpoints */
.klaro-ide {
  /* The switch - the box around the slider */
  /*
We declare all variables here as default so that they are easy to override...
*/
  /* Border, shadows, ... */
  /* Colors */
  /* Spacing */
  /* Breakpoints */
  /*
We declare all variables here as default so that they are easy to override...
*/
  /* Border, shadows, ... */
  /* Colors */
  /* Spacing */
  /* Breakpoints */
  /*
We declare all variables here as default so that they are easy to override...
*/
  /* Border, shadows, ... */
  /* Colors */
  /* Spacing */
  /* Breakpoints */
}

.klaro-ide .cm-switch-container {
  display: block;
  position: relative;
  padding: 10px;
  padding-left: 66px;
  line-height: 20px;
  vertical-align: middle;
  min-height: 40px;
  border-bottom: 1px solid #ccc;
}

.klaro-ide .cm-switch-container:last-child {
  border-bottom: 0;
}

.klaro-ide .cm-switch-container:first-child {
  margin-top: 0;
}

.klaro-ide .cm-switch-container p {
  margin-top: 0;
}

.klaro-ide .cm-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

.klaro-ide .cm-list-input:checked + .cm-list-label .slider {
  background-color: #9082ff;
  background-color: var(--green1, #9082ff);
}

.klaro-ide .cm-list-input.half-checked:checked + .cm-list-label .slider {
  background-color: #9082ff;
  background-color: var(--green1, #9082ff);
  opacity: 0.6;
}

.klaro-ide .cm-list-input.half-checked:checked + .cm-list-label .slider::before {
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

.klaro-ide .cm-list-input.only-required + .cm-list-label .slider {
  background-color: #24cc9a;
  background-color: var(--green2, #24cc9a);
  opacity: 0.8;
}

.klaro-ide .cm-list-input.only-required + .cm-list-label .slider::before {
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

.klaro-ide .cm-list-input.required:checked + .cm-list-label .slider {
  background-color: #24cc9a;
  background-color: var(--green2, #24cc9a);
  opacity: 0.8;
  cursor: not-allowed;
}

.klaro-ide .slider {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
}

.klaro-ide .cm-list-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 50px;
  height: 30px;
}

.klaro-ide .cm-list-title {
  font-size: 14px;
  font-weight: 600;
}

.klaro-ide .cm-list-description {
  font-size: 14px;
  padding-top: 4px;
  color: #666;
}

.klaro-ide .cm-list-label {
  /* The slider */
  /* Rounded sliders */
}

.klaro-ide .cm-list-label .cm-switch {
  position: absolute;
  left: 0;
}

.klaro-ide .cm-list-label .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  width: 50px;
  display: inline-block;
}

.klaro-ide .cm-list-label .slider::before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 5px;
  background-color: #fff;
  transition: 0.4s;
}

.klaro-ide .cm-list-label .slider.round {
  border-radius: 30px;
}

.klaro-ide .cm-list-label .slider.round::before {
  border-radius: 50%;
}

.klaro-ide .cm-list-label input:focus + .slider {
  box-shadow-color: #48dfb2;
  box-shadow-color: var(--green3, #48dfb2);
  box-shadow: 0 0 1px #48dfb2;
}

.klaro-ide .cm-list-label input:checked + .slider::before {
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.klaro-ide .cm-list-input:focus + .cm-list-label .slider {
  box-shadow: 0 4px 6px 0 rgba(125, 125, 125, 0.2), 5px 5px 10px 0 rgba(125, 125, 125, 0.19);
}

.klaro-ide .cm-list-input:checked + .cm-list-label .slider::before {
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.klaro-ide .cm-language-select,
.klaro-ide .cm-purpose-select {
  border: 1px solid #eee;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
  padding: 12px;
  margin-bottom: 12px;
}

.klaro-ide .cm-language-select ul.cm-languages,
.klaro-ide .cm-language-select ul.cm-purposes,
.klaro-ide .cm-purpose-select ul.cm-languages,
.klaro-ide .cm-purpose-select ul.cm-purposes {
  margin-top: -4px;
}

.klaro-ide .cm-language-select ul.cm-languages li,
.klaro-ide .cm-language-select ul.cm-purposes li,
.klaro-ide .cm-purpose-select ul.cm-languages li,
.klaro-ide .cm-purpose-select ul.cm-purposes li {
  display: inline-block;
  margin-right: 4px;
  margin-top: 4px;
  padding: 2px 4px;
  background: #9082ff;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
}

.klaro-ide .cm-language-select ul.cm-languages li a,
.klaro-ide .cm-language-select ul.cm-purposes li a,
.klaro-ide .cm-purpose-select ul.cm-languages li a,
.klaro-ide .cm-purpose-select ul.cm-purposes li a {
  color: #fff;
}

.klaro-ide .cm-search-select {
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
}

.klaro-ide .cm-search-select input:not(:focus) ~ .cm-candidates {
  display: none;
}

.klaro-ide .cm-search-select .cm-candidates:hover {
  display: block !important;
}

.klaro-ide .cm-search-select .cm-candidates {
  position: absolute;
  top: 55px;
  left: 0;
  z-index: 10;
  background: #fff;
  margin-bottom: 10px;
  border: 1px solid #9082ff;
  border-radius: 4px;
  width: 100%;
}

.klaro-ide .cm-search-select .cm-candidates .cm-candidate {
  border-bottom: 1px solid #9082ff;
  padding: 12px;
  cursor: pointer;
}

.klaro-ide .cm-search-select .cm-candidates .cm-candidate p {
  color: #999;
  font-size: 14px;
}

.klaro-ide .cm-search-select .cm-candidates .cm-candidate:last-child {
  border-bottom: 0;
}

.klaro-ide .cm-obj-selector {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 20px;
  width: 100%;
  height: 40px;
  overflow: visible;
}

@media (min-width: 768px) {
  .klaro-ide .cm-obj-selector {
    width: 300px;
  }
}

.klaro-ide .cm-obj-selector span.cm-obj-selector-more {
  display: block;
  position: absolute;
  right: 0.5em;
  padding-top: 0.6em;
  padding-right: 0.3em;
  z-index: 2;
  pointer-events: none;
  -ms-transform: scaleY(0.6) scaleX(0.8);
      transform: scaleY(0.6) scaleX(0.8);
  font-weight: 800;
}

.klaro-ide .cm-obj-selector ul {
  top: 0;
  left: 0;
  z-index: 1;
  margin: 0;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.klaro-ide .cm-obj-selector ul li {
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.33);
  margin: 0;
  width: auto;
  display: none;
  width: 100%;
  border-bottom: 1px solid #aaa;
  order: 2;
}

.klaro-ide .cm-obj-selector ul li:last-child {
  border-bottom: 0;
}

.klaro-ide .cm-obj-selector ul li.cm-obj-is-active {
  display: flex;
  background: #fafafa;
  order: 1;
}

.klaro-ide .cm-obj-selector ul li a.cm-obj-item {
  width: 100%;
  padding: 8px;
  padding-right: 40px;
}

.klaro-ide .cm-obj-selector ul li.cm-obj-add {
  padding: 8px;
  display: none;
  justify-content: space-between;
  order: 3;
}

.klaro-ide .cm-obj-selector ul li.cm-obj-add input {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 4px;
  font-size: 14px;
  color: #888;
  border: 1px solid #2581c4;
  border-radius: 4px;
}

.klaro-ide .cm-obj-selector ul li.cm-obj-add a.cm-btn {
  padding: 8px;
  border-radius: 4px;
  flex-grow: 0;
  color: #fff;
  background: #2581c4;
  flex-shrink: 0;
  margin-left: 10px;
  width: auto;
  display: inline-block;
}

.klaro-ide .cm-obj-selector ul:not(.cm-is-active) li.cm-obj-is-active {
  background: #fff;
}

.klaro-ide .cm-obj-selector ul.cm-is-active {
  z-index: 4;
}

.klaro-ide .cm-obj-selector ul.cm-is-active li {
  display: flex;
}

.klaro-ide .cm-switch {
  padding: 12px;
}

.klaro-ide p.cm-description {
  font-size: 14px;
  padding-top: 2px;
  margin-bottom: 4px;
  color: #666;
}

.klaro-ide .cm-select {
  margin-top: 16px;
}

.klaro-ide .cm-select select {
  display: block;
  width: 100%;
  padding: 12px 5px;
  border: 1px solid #2581c4;
  border-radius: 4px;
  font-size: 16px;
  color: #888;
  background: #fff;
  text-indent: 5px;
}

.klaro-ide .cm-select select[disabled] {
  background-color: #eee;
}

.klaro-ide .cm-retracting-label-input {
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 10px 0 6px;
  margin-top: 4px;
  /*
    > .cm-input:not([disabled]):placeholder-shown:not(:focus) + .cm-label {
        transform: none;
        border: none;
        color: #888;
    }
*/
}

.klaro-ide .cm-retracting-label-input > .cm-label {
  background-color: #fff;
  position: absolute;
  top: 16px;
  left: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - (2 * 8px));
  overflow: hidden;
  pointer-events: none;
  transition: transform 150ms cubic-bezier(0.47, 0, 0.74, 0.71), opacity 150ms cubic-bezier(0.47, 0, 0.74, 0.71), color 150ms cubic-bezier(0.47, 0, 0.74, 0.71);
  padding: 0 8px;
  -ms-transform: scale(0.75) translateX(-16%) translateY(-26px);
      transform: scale(0.75) translateX(-16%) translateY(-26px);
  border-radius: 4px;
  border: 1px solid #2581c4;
}

.klaro-ide .cm-input {
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #9082ff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 12px 18px;
  width: 100%;
}

.klaro-ide .cm-input[disabled] {
  background-color: #eee;
}

@media (min-width: 768px) {
  .klaro-ide .cm-tabs span.cm-more {
    display: none;
  }
}

.klaro-ide .cm-tabs {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .klaro-ide .cm-tabs span.cm-tabs-more {
    display: none;
  }
}

@media (max-width: 768px) {
  .klaro-ide .cm-tabs {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.33);
  }
  .klaro-ide .cm-tabs span.cm-tabs-more {
    display: block;
    position: absolute;
    right: 0.5em;
    padding-top: 0.6em;
    padding-right: 0.3em;
    -ms-transform: scaleY(0.6) scaleX(0.8);
        transform: scaleY(0.6) scaleX(0.8);
    font-weight: 800;
  }
  .klaro-ide .cm-tabs ul {
    flex-wrap: wrap;
  }
  .klaro-ide .cm-tabs ul li {
    display: block;
    position: relative;
    width: 100%;
    text-align: left;
  }
  .klaro-ide .cm-tabs ul li a {
    justify-content: left;
    border-bottom: 0 !important;
  }
  .klaro-ide .cm-tabs:not(.cm-tabs-active) ul li:not(.cm-tab-is-active) {
    display: none;
  }
  .klaro-ide .cm-tabs.cm-tabs-active ul li {
    order: 2;
    display: block;
  }
  .klaro-ide .cm-tabs.cm-tabs-active ul li.cm-tab-is-active {
    background: #eee;
    order: 1;
  }
}

.klaro-ide .cm-tabs:not(:last-child) {
  margin-bottom: 1.5rem;
}

.klaro-ide .cm-tabs ul {
  align-items: center;
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
  border-bottom: 1px solid #dbdbdb;
  margin: 0;
  padding: 0;
}

.klaro-ide .cm-tabs ul li {
  display: block;
}

.klaro-ide .cm-tabs ul li a {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  margin-bottom: -1px;
  padding: 0.5em 1em;
  color: #4a4a4a;
  border-bottom: 1px solid #dbdbdb;
}

.klaro-ide .cm-tabs ul li.cm-tab-is-active a {
  color: #2581c4;
  border-bottom-color: #2581c4;
}

@media (max-width: 767px) {
  .klaro-ide .cm-tabs.tabs-active {
    position: relative;
    min-height: 40px;
    overflow: visible;
    z-index: 10;
  }
  .klaro-ide .cm-tabs.tabs-active ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    height: auto;
    background: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.33);
  }
  .klaro-ide .cm-tabs.tabs-active ul li {
    margin: 0 !important;
  }
  .klaro-ide .cm-tabs.tabs-active ul li:not(.cm-tab-is-active) {
    order: 2;
    display: block;
  }
  .klaro-ide .cm-tabs.tabs-active ul li.cm-tab-is-active {
    order: 1;
    background: #aaa;
    display: block;
  }
}

.klaro-ide .cm-list .cm-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-items: flex-end;
}

@media (min-width: 768px) {
  .klaro-ide .cm-list .cm-item span.cm-is-action {
    display: none;
  }
  .klaro-ide .cm-list .cm-item:hover span.cm-is-action {
    display: inline-block;
  }
}

.klaro-ide .cm-list .cm-item:last-child {
  border-bottom: 0;
}

.klaro-ide .cm-list .cm-item:nth-child(2n) {
  background: #fafafa;
}

.klaro-ide .cm-list .cm-item.cm-is-header {
  font-weight: 600;
  margin-bottom: 4px;
  background: none;
}

.klaro-ide .cm-list .cm-item.cm-is-card {
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.33);
  border-radius: 4px;
  margin-bottom: 24px;
}

.klaro-ide .cm-list .cm-item.cm-is-clickable {
  cursor: pointer;
}

.klaro-ide .cm-list .cm-item.cm-is-expandable {
  cursor: pointer;
}

.klaro-ide .cm-list .cm-item form {
  margin: 0;
}

.klaro-ide .cm-list .cm-item form label.label {
  font-weight: 300;
}

.klaro-ide .cm-list .cm-item span.cm-is-action {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.klaro-ide .cm-list .cm-item .cm-col {
  align-content: center;
  flex-basis: 0;
  margin: 8px;
  flex-grow: 1;
}

.klaro-ide .cm-list .cm-item .cm-col.cm-is-xs {
  flex-grow: 1;
}

.klaro-ide .cm-list .cm-item .cm-col.cm-is-sm {
  flex-grow: 2;
}

.klaro-ide .cm-list .cm-item .cm-col.cm-is-md {
  flex-grow: 5;
}

.klaro-ide .cm-list .cm-item .cm-col.cm-is-lg {
  flex-grow: 10;
}

.klaro-ide .cm-list .cm-item .cm-col.cm-is-xl {
  flex-grow: 20;
}

.klaro-ide .cm-list .cm-item .cm-col.cm-is-icon {
  flex-basis: 50px;
  text-align: right;
  flex-grow: 0;
}

.klaro-ide .cm-list .cm-item .cm-content {
  flex: 10;
}

.klaro-ide .cm-dropdown {
  position: relative;
}

.klaro-ide .cm-dropdown > button {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  font-size: 1em;
  border: 0;
  background-color: transparent;
}

.klaro-ide .cm-dropdown > button:focus, .klaro-ide .cm-dropdown > button:hover {
  border-radius: 25px;
  background-color: #aaa;
}

.klaro-ide .cm-dropdown > .cm-dropdowncontent {
  display: none;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.33);
  width: auto;
}

.klaro-ide .cm-dropdown > .cm-dropdowncontent.cm-dropdownexpanded {
  display: block;
  position: absolute;
  top: 35px;
  z-index: 100;
  left: 0;
  border-radius: 4px;
}

.klaro-ide .cm-dropdown > .cm-dropdowncontent ul.cm-dropdownmenu {
  list-style: none;
  text-align: left;
  max-width: 300px;
  min-width: 200px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border: 1px solid #ececec;
  border-radius: 4px;
}

.klaro-ide .cm-dropdown > .cm-dropdowncontent ul.cm-dropdownmenu li {
  height: auto;
}

.klaro-ide .cm-dropdown > .cm-dropdowncontent ul.cm-dropdownmenu li a {
  display: block;
  padding: 8px;
  padding-left: 16px;
}

.klaro-ide .cm-dropdown > .cm-dropdowncontent ul.cm-dropdownmenu li a span {
  display: inline-flex;
  align-items: center;
}

.klaro-ide .cm-dropdown > .cm-dropdowncontent ul.cm-dropdownmenu li a .icon {
  margin-right: 0.45em;
}

.klaro-ide .cm-dropdown > .cm-dropdowncontent ul.cm-dropdownmenu li a:hover {
  background-color: #2581c4;
  color: #fff;
}

.klaro-ide .cm-dropdown.is-right .cm-dropdowncontent.cm-dropdownexpanded {
  right: 0;
  left: auto;
}

.klaro-ide label {
  display: inline-block;
}

.klaro-ide .cm-global-fields,
.klaro-ide .cm-config-controls,
.klaro-ide .cm-translations-fields,
.klaro-ide .cm-service-fields {
  max-width: 600px;
}

.klaro-ide .cm-json .cm-file-import {
  display: none;
}

.klaro-ide .cm-json .cm-upload-label {
  cursor: pointer;
}

.klaro-ide .cm-json .cm-upload-label button {
  pointer-events: none;
}

.klaro-ide .cm-json pre code {
  white-space: pre-wrap;
}

.klaro-ide .cm-message {
  padding: 12px;
}

.klaro-ide .cm-message.cm-error, .klaro-ide .cm-message.cm-success {
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
  color: #fff;
  margin-bottom: 24px;
}

.klaro-ide .cm-message.cm-error {
  background: #e15669;
}

.klaro-ide .cm-message.cm-success {
  background: #24cc9a;
}

.klaro-ide .cm-space-sm {
  margin-bottom: 8px !important;
}

.klaro-ide .cm-space-md {
  margin-bottom: 12px !important;
}

.klaro-ide .cm-space-lg {
  margin-bottom: 24px !important;
}

.klaro-ide .cm-json h1,
.klaro-ide .cm-json h2,
.klaro-ide .cm-json h3,
.klaro-ide .cm-json h4,
.klaro-ide .cm-global-fields h1,
.klaro-ide .cm-global-fields h2,
.klaro-ide .cm-global-fields h3,
.klaro-ide .cm-global-fields h4,
.klaro-ide .cm-config-controls h1,
.klaro-ide .cm-config-controls h2,
.klaro-ide .cm-config-controls h3,
.klaro-ide .cm-config-controls h4,
.klaro-ide .cm-translations-fields h1,
.klaro-ide .cm-translations-fields h2,
.klaro-ide .cm-translations-fields h3,
.klaro-ide .cm-translations-fields h4,
.klaro-ide .cm-service-fields h1,
.klaro-ide .cm-service-fields h2,
.klaro-ide .cm-service-fields h3,
.klaro-ide .cm-service-fields h4 {
  font-size: 24px;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
}

.klaro-ide .cm-json h1,
.klaro-ide .cm-global-fields h1,
.klaro-ide .cm-config-controls h1,
.klaro-ide .cm-translations-fields h1,
.klaro-ide .cm-service-fields h1 {
  text-transform: none;
  border-bottom: 1px solid #aaa;
  margin-bottom: 20px;
  display: inline-block;
}

.klaro-ide .cm-json h2,
.klaro-ide .cm-global-fields h2,
.klaro-ide .cm-config-controls h2,
.klaro-ide .cm-translations-fields h2,
.klaro-ide .cm-service-fields h2 {
  font-size: 20px;
}

.klaro-ide .cm-json h3,
.klaro-ide .cm-global-fields h3,
.klaro-ide .cm-config-controls h3,
.klaro-ide .cm-translations-fields h3,
.klaro-ide .cm-service-fields h3 {
  font-size: 16px;
}

.klaro-ide .cm-json h4,
.klaro-ide .cm-global-fields h4,
.klaro-ide .cm-config-controls h4,
.klaro-ide .cm-translations-fields h4,
.klaro-ide .cm-service-fields h4 {
  font-size: 12px;
  font-weight: 600;
}

.klaro-ide p.cm-section-description {
  text-align: justify;
  margin-top: 8px;
  margin-bottom: 24px;
  color: #666;
}

.klaro-ide .cm-purpose-order ul {
  margin-top: 12px;
  margin-bottom: 12px;
}

.klaro-ide .cm-purpose-order ul li {
  display: flex;
}

.klaro-ide .cm-purpose-order ul li span.cm-buttons {
  flex-grow: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
  border-radius: 4px;
}

.klaro-ide .cm-purpose-order ul li span.cm-buttons a {
  padding: 12px;
  display: inline-block;
}

.klaro-ide .cm-purpose-order ul li span.cm-buttons a:hover {
  background: #9082ff;
}

.klaro-ide .cm-purpose-order ul li span.cm-value {
  flex-grow: 1;
  padding: 12px;
}

.klaro-ide .cm-translations-fields .cm-translations-for-key {
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 24px;
}

.klaro-ide .cm-translations-fields .cm-translations-for-key li {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.klaro-ide .cm-translations-fields .cm-translations-for-key li span.cm-lang {
  font-family: Courier;
  display: block;
  padding: 20px;
  margin-top: 5px;
  flex-grow: 0;
}

.klaro-ide .cm-service-list .cm-status,
.klaro-ide .cm-config-list .cm-status {
  text-align: left;
}

.klaro-ide .cm-service-list .cm-status span,
.klaro-ide .cm-config-list .cm-status span {
  padding-left: 10px;
}

.klaro-ide .cm-service-list .cm-status .cm-status-is-active,
.klaro-ide .cm-config-list .cm-status .cm-status-is-active {
  color: #9082ff;
}

.klaro-ide .cm-service-list .cm-status .cm-status-is-inactive,
.klaro-ide .cm-config-list .cm-status .cm-status-is-inactive {
  color: #da2c43;
}

.klaro-ide .cm-service-list .cm-name,
.klaro-ide .cm-config-list .cm-name {
  text-transform: uppercase;
  font-weight: 600;
}

.klaro-ide p.cm-no-cookies,
.klaro-ide p.cm-no-services {
  margin-top: 6px;
  margin-bottom: 6px;
  font-weight: 600;
}

.klaro-ide .cm-cookie-config .cm-cookie-forms .cm-cookie-form {
  border-bottom: 1px solid #aaa;
}

.klaro-ide .cm-demo .cm-config-controls .cm-retracting-label-input {
  width: auto;
  flex-grow: 1;
  margin-right: 12px;
}

.klaro-ide .cm-demo .cm-config-controls:first-child {
  border-bottom: 1px solid #aaa;
}

.klaro-ide .cm-config-controls {
  margin-top: 8px;
  margin-bottom: 24px;
  max-width: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
}

.klaro-ide .cm-config-controls h2 {
  margin-right: 24px;
  display: block;
  flex-grow: 1;
}

.klaro-ide .cm-config-controls .cm-control {
  flex-shrink: 1;
  display: inline-block;
  margin-bottom: 20px;
}

.klaro-ide .cm-config-controls .cm-control .cm-retracting-label-input {
  max-width: 400px;
  display: block;
}

.klaro-ide .cm-config-controls .cm-control input {
  max-width: 400px;
}

.klaro-ide .cm-config-controls .cm-control select {
  padding: 12px;
  display: block;
  border: 1px solid #9082ff;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 12px;
}

.klaro-ide .cm-config-controls .cm-control-button {
  border: 0;
  padding: 12px;
  margin-top: 4px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.33);
  background: #459cdc;
  color: #fff;
  border-radius: 4px;
  margin-right: 6px;
}

.klaro-ide .cm-config-controls .cm-control-button.cm-delete {
  float: right;
  background: #f50;
}

.klaro-ide .cm-config-controls .cm-control-button.cm-secondary {
  background: #e15669;
}

.klaro-ide .cm-config-controls .cm-control-button.cm-success {
  background: #9082ff;
}

.klaro-ide .cm-config-controls .cm-control-button:disabled {
  background: #aaa;
  cursor: not-allowed;
}

.klaro-ide .cookie-modal .cm-modal.cm-ide {
  z-index: 1001;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  max-height: 98%;
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  position: fixed;
  overflow: auto;
  background: #333;
  color: #eee;
}

@media (min-width: 1020px) {
  .klaro-ide .cookie-modal .cm-modal.cm-ide {
    border-radius: 4px;
    position: relative;
    margin: 0 auto;
    max-width: 1000px;
    height: auto;
    width: auto;
  }
}

